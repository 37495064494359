import { type CssVarsThemeOptions } from '@mui/material';
import merge from 'lodash/merge';

import { S_PROPERTY_MAPPING, S_THEME_OPTIONS, type SPropertyMapping } from '@/config/schema/mui-theme';

export class ThemeOverrides {
    public readonly themeOptions: CssVarsThemeOptions;

    public readonly propertyMapping: SPropertyMapping;

    public readonly customCSS: string;

    public readonly googleMaps: google.maps.MapTypeStyle[];

    constructor(json: Record<string, any> = {}) {
        this.themeOptions = S_THEME_OPTIONS.cast(json.themeOptions, { stripUnknown: true });
        this.propertyMapping = S_PROPERTY_MAPPING.cast(json.propertyMapping, { stripUnknown: true });
        this.customCSS = json.customCSS ?? '';
        this.googleMaps = json.googleMaps;
    }

    public applyTo(base: CssVarsThemeOptions): CssVarsThemeOptions {
        return merge(base, this.themeOptions);
    }
}
