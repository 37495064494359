import { type ButtonProps } from '@mui/material';
import { number, object, string, type InferType } from 'yup';

import { S_CSS_COLOR, S_CSS_NUMBER, S_REM_UNIT, S_TYPOGRAPHY_STRING } from '@/config/schema/css';

/*
 * PaletteOptions Schema
 */

const S_NUMERIC = number()
    .transform((current, original) => (original === '' ? undefined : Number.parseFloat(current)))
    .default(undefined);

const S_SIMPLE_PALETTE_COLOR = object({
    light: S_CSS_COLOR,
    main: S_CSS_COLOR.required(),
    dark: S_CSS_COLOR,
    contrastText: S_CSS_COLOR,
})
    .transform(current => (current.main ? current : undefined))
    .default(undefined);

export const S_COLOR_SYSTEM_OPTIONS = object({
    contrastThreshold: S_NUMERIC.min(1).max(5),
    tonalOffset: S_NUMERIC.min(0).max(1),
    primary: S_SIMPLE_PALETTE_COLOR,
    secondary: S_SIMPLE_PALETTE_COLOR,
    error: S_SIMPLE_PALETTE_COLOR,
    warning: S_SIMPLE_PALETTE_COLOR,
    info: S_SIMPLE_PALETTE_COLOR,
    success: S_SIMPLE_PALETTE_COLOR,
    eco: S_SIMPLE_PALETTE_COLOR,
    MapPin: object({
        bg: S_CSS_COLOR,
        color: S_CSS_COLOR,
        border: S_CSS_COLOR,
    }).default(undefined),
    // grey: object({
    //     50: S_CSS_COLOR,
    //     100: S_CSS_COLOR,
    //     200: S_CSS_COLOR,
    //     300: S_CSS_COLOR,
    //     400: S_CSS_COLOR,
    //     500: S_CSS_COLOR,
    //     600: S_CSS_COLOR,
    //     700: S_CSS_COLOR,
    //     800: S_CSS_COLOR,
    //     900: S_CSS_COLOR,
    //     A100: S_CSS_COLOR,
    //     A200: S_CSS_COLOR,
    //     A400: S_CSS_COLOR,
    //     A700: S_CSS_COLOR,
    // }).default(undefined),
    text: object({
        primary: S_CSS_COLOR,
        secondary: S_CSS_COLOR,
        disabled: S_CSS_COLOR,
    }).default(undefined),
    divider: S_CSS_COLOR,
    action: object({
        active: S_CSS_COLOR,
        activatedOpacity: S_NUMERIC.min(0).max(1),
        hover: S_CSS_COLOR,
        hoverOpacity: S_NUMERIC.min(0).max(1),
        selected: S_CSS_COLOR,
        selectedOpacity: S_NUMERIC.min(0).max(1),
        disabled: S_CSS_COLOR,
        disabledBackground: S_CSS_COLOR,
        disabledOpacity: S_NUMERIC.min(0).max(1),
        focus: S_CSS_COLOR,
        focusOpacity: S_NUMERIC.min(0).max(1),
    }).default(undefined),
    background: object({
        paper: S_CSS_COLOR,
        default: S_CSS_COLOR,
    }).default(undefined),
    Alert: object({
        errorColor: S_CSS_COLOR,
        infoColor: S_CSS_COLOR,
        successColor: S_CSS_COLOR,
        warningColor: S_CSS_COLOR,
        errorFilledBg: S_CSS_COLOR,
        infoFilledBg: S_CSS_COLOR,
        successFilledBg: S_CSS_COLOR,
        warningFilledBg: S_CSS_COLOR,
        errorFilledColor: S_CSS_COLOR,
        infoFilledColor: S_CSS_COLOR,
        successFilledColor: S_CSS_COLOR,
        warningFilledColor: S_CSS_COLOR,
        errorStandardBg: S_CSS_COLOR,
        infoStandardBg: S_CSS_COLOR,
        successStandardBg: S_CSS_COLOR,
        warningStandardBg: S_CSS_COLOR,
        errorIconColor: S_CSS_COLOR,
        infoIconColor: S_CSS_COLOR,
        successIconColor: S_CSS_COLOR,
        warningIconColor: S_CSS_COLOR,
    }).default(undefined),
    AppBar: object({
        defaultBg: S_CSS_COLOR,
        defaultShadow: string(),
        darkBg: S_CSS_COLOR,
        darkColor: S_CSS_COLOR,
    }).default(undefined),
    TilePart: object({
        attachedTopShadow: string(),
        attachedBottomShadow: string(),
    }).default(undefined),
    Avatar: object({
        defaultBg: S_CSS_COLOR,
    }).default(undefined),
    Button: object({
        inheritContainedBg: S_CSS_COLOR,
        inheritContainedHoverBg: S_CSS_COLOR,
    }).default(undefined),
    Chip: object({
        defaultBorder: S_CSS_COLOR,
        defaultAvatarColor: S_CSS_COLOR,
        defaultIconColor: S_CSS_COLOR,
    }).default(undefined),
    FilledInput: object({
        bg: S_CSS_COLOR,
        hoverBg: S_CSS_COLOR,
        disabledBg: S_CSS_COLOR,
        border: S_CSS_COLOR,
        hoverBorder: S_CSS_COLOR,
        disabledBorder: S_CSS_COLOR,
    }).default(undefined),
    LinearProgress: object({
        primaryBg: S_CSS_COLOR,
        secondaryBg: S_CSS_COLOR,
        errorBg: S_CSS_COLOR,
        infoBg: S_CSS_COLOR,
        successBg: S_CSS_COLOR,
        warningBg: S_CSS_COLOR,
    }).default(undefined),
    Skeleton: object({
        bg: S_CSS_COLOR,
    }).default(undefined),
    Slider: object({
        primaryTrack: S_CSS_COLOR,
        secondaryTrack: S_CSS_COLOR,
        errorTrack: S_CSS_COLOR,
        infoTrack: S_CSS_COLOR,
        successTrack: S_CSS_COLOR,
        warningTrack: S_CSS_COLOR,
    }).default(undefined),
    SnackbarContent: object({
        bg: S_CSS_COLOR,
        color: S_CSS_COLOR,
    }).default(undefined),
    SpeedDialAction: object({
        fabHoverBg: S_CSS_COLOR,
    }).default(undefined),
    StepConnector: object({
        border: S_CSS_COLOR,
    }).default(undefined),
    StepContent: object({
        border: S_CSS_COLOR,
    }).default(undefined),
    Switch: object({
        defaultColor: S_CSS_COLOR,
        defaultDisabledColor: S_CSS_COLOR,
        primaryDisabledColor: S_CSS_COLOR,
        secondaryDisabledColor: S_CSS_COLOR,
        errorDisabledColor: S_CSS_COLOR,
        infoDisabledColor: S_CSS_COLOR,
        successDisabledColor: S_CSS_COLOR,
        warningDisabledColor: S_CSS_COLOR,
    }).default(undefined),
    Tooltip: object({
        bg: S_CSS_COLOR,
    }).default(undefined),
}).default(undefined);

/*
 * TypographyOptions Schema
 */

export const S_TYPOGRAPHY_STYLE = object({
    fontSize: S_REM_UNIT,
    lineHeight: S_REM_UNIT,
    fontFamily: S_TYPOGRAPHY_STRING,
    fontWeight: S_CSS_NUMBER,
    letterSpacing: S_CSS_NUMBER,
}).default(undefined);

export const S_TYPOGRAPHY_OPTIONS = object({
    fontFamily: S_TYPOGRAPHY_STRING,
    fontSize: S_CSS_NUMBER,
    fontWeightLight: S_CSS_NUMBER,
    fontWeightRegular: S_CSS_NUMBER,
    fontWeightMedium: S_CSS_NUMBER,
    fontWeightBold: S_CSS_NUMBER,

    h3: S_TYPOGRAPHY_STYLE,
    h3Sub: S_TYPOGRAPHY_STYLE,
    h4: S_TYPOGRAPHY_STYLE,
    h4Sub: S_TYPOGRAPHY_STYLE,
    h5: S_TYPOGRAPHY_STYLE,
    h5Sub: S_TYPOGRAPHY_STYLE,
    h6: S_TYPOGRAPHY_STYLE,
    price: S_TYPOGRAPHY_STYLE,
    body1: S_TYPOGRAPHY_STYLE,
    body2: S_TYPOGRAPHY_STYLE,
    body3: S_TYPOGRAPHY_STYLE,
    body4: S_TYPOGRAPHY_STYLE,
}).default(undefined);

/*
 * ShapeOptions Schema
 */

export const S_SHAPE_OPTIONS = object({
    borderRadius: S_CSS_NUMBER,
}).default(undefined);

/*
 * ThemeOptions Schema
 */

// export const S_THEME_OPTIONS = object({
//     palette: S_PALETTE_OPTIONS,
//     typography: S_TYPOGRAPHY_OPTIONS,
//     shape: S_SHAPE_OPTIONS,
// });

export const S_THEME_OPTIONS = object({
    colorSchemes: object({
        light: object({
            palette: S_COLOR_SYSTEM_OPTIONS,
        }),
        dark: object({
            palette: S_COLOR_SYSTEM_OPTIONS,
        }),
    }),
    typography: S_TYPOGRAPHY_OPTIONS,
    shape: S_SHAPE_OPTIONS,
});

export type SThemeOptions = InferType<typeof S_THEME_OPTIONS>;

/*
 * Property Mapping Schema
 */

export const BUTTON_VARIANTS: Array<ButtonProps['variant']> = ['contained', 'outlined', 'text'];
export const BUTTON_COLORS: Array<ButtonProps['color']> = [
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
];

export const S_PROPERTY_MAPPING = object({
    actionButton: object({
        category: object({
            primary: string().oneOf(BUTTON_VARIANTS).default('contained'),
            secondary: string().oneOf(BUTTON_VARIANTS).default('text'),
            tertiary: string().oneOf(BUTTON_VARIANTS).default('outlined'),
        }),
        intent: object({
            positive: string().oneOf(BUTTON_COLORS).default('success'),
            normal: string().oneOf(BUTTON_COLORS).default('primary'),
            neutral: string().oneOf(BUTTON_COLORS).default('inherit'),
            negative: string().oneOf(BUTTON_COLORS).default('error'),
        }),
    }),
});

export type SPropertyMapping = InferType<typeof S_PROPERTY_MAPPING>;

export const S_THEME_OVERRIDES = object({
    themeOptions: S_THEME_OPTIONS.optional(),
    propertyMapping: S_PROPERTY_MAPPING,
    customCSS: string().optional(),
});

export type SThemeOverrides = InferType<typeof S_THEME_OVERRIDES>;
